@font-face {
    font-family: "Roboto";
    src: url("./_assets/fonts/Roboto/Roboto-Regular.ttf");
}

body {
    color: RGB(55, 55, 55);
    font-size: 16px !important;
    font-weight: 400;
    background-color:  RGB(248, 249, 255);
}

.font__size--main-mobile {
    font-size: 18px;
}

.font__size--main {
    font-size: 16px;
}

.font__size--sub {
    font-size: 14px;
}

.main__text {
    color: RGB(55, 55, 55);
}

.sub__text {
    color: RGB(111, 111, 111);
}

.telehealth__card {
  border: 0px; 
  font-size: 14px;
  box-shadow: RGB(0 0 0 / 6%) 0px 2px 5px 0px;
  font-weight: 400;
}

.empty {
    background-size: contain;
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
}

.footer {
  line-height: unset;
  padding-top: 1em;
}