$blue: #1AB2E9;
$body-bg: #F3F6F6;
  
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';

body {
    overscroll-behavior-y: contain;
}

.instructions {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}